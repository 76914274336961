/* RESPONSIBLE TEAM: team-actions */
import { attr, belongsTo, hasMany } from '@ember-data/model';
import Admin from 'embercom/models/admin';
//@ts-ignore no type declaration available for array
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { isPresent, isEmpty } from '@ember/utils';
import { validator, buildValidations } from 'ember-cp-validations';
import { humanize } from 'ember-cli-string-helpers/helpers/humanize';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';

//@ts-ignore no type declaration available for ember-copy
import { copy } from 'ember-copy';
import type ActionObjectMapping from './action-object-mapping';
import type ActionHeader from './action-header';
import type ParamDescriptor from './param-descriptor';
import type TestResult from './test-result';
import type ResponseField from './response-field';
import type MockResponse from './mock-response';
import type SuggestedQuestion from './suggested-question';
import { BaseAction } from 'embercom/models/workflow-connector/base-action';
import _ from 'underscore';
import type ApiMappableObject from 'embercom/models/workflow-connector/api-mappable-object';
import type ContextualIdentifierConfig from './customer-data-verification/contextual-identifier-config';

const Validations = buildValidations({
  name: [
    validator('presence', {
      presence: true,
    }),
  ],
  url: [
    validator('presence', {
      presence: true,
      messageKey: 'workflow-connector.builder.body.request.url.https',
    }),
    validator('format', { type: 'url' }),
  ],
  description: [
    validator('presence', {
      presence: true,
      disabled: _.isEqual('model.usage', 'workflow_and_inbox'),
    }),
  ],
});

export enum ContentType {
  DEFAULT = 'default',
  JSON = 'application/json',
  TEXT = 'text/plain',
}

export default class Action extends BaseAction.extend(Validations) {
  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr('string') declare state: string;
  @attr('string') declare url: string;
  @attr('string') declare httpMethod: string;
  @attr('string') declare body: string | null;
  @attr('boolean') declare isTemplate: boolean;
  @attr('boolean', { defaultValue: false }) declare isRecommendedAction: boolean;
  @attr('string') declare tokenId: string | null;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('number') declare createdByAdminId: number;
  @attr('number') declare updatedByAdminId: number;
  @attr('string') declare appPackageCode: string;
  @attr('boolean') declare finEnabled: boolean;
  @attr('string') declare usage: string;
  @attr('string') declare iconUrl: string;
  @attr('number') declare sentCount: number;
  @attr('number') declare resolvedCount: number;
  @attr('boolean') declare includeFullResponse: boolean;
  @attr('number') declare conversationUsage: number;
  @attr('string') declare configurationResponseType: string;
  @attr('boolean') declare finWriteAction: boolean;
  @attr('string') declare placeholderUrl: string;
  @attr('boolean') declare otpAuthEnabled: boolean;
  @attr('boolean', { allowNull: true }) declare noAuthenticationChosen: boolean | null;
  @fragmentArray('workflow-connector/action-header') declare headers: ActionHeader[];
  @fragmentArray('workflow-connector/action-object-mapping')
  declare objectMappings: ActionObjectMapping[];
  @fragmentArray('workflow-connector/param-descriptor') declare paramDescriptors: ParamDescriptor[];
  @fragment('workflow-connector/test-result') declare testResult: TestResult;
  @fragment('workflow-connector/mock-response') declare mockResponse: MockResponse;
  @fragmentArray('workflow-connector/response-field') declare responseFields: ResponseField[];
  @fragmentArray('workflow-connector/suggested-question')
  declare suggestedQuestions: SuggestedQuestion[];
  @fragmentArray('workflow-connector/api-mappable-object')
  declare apiMappableObjects: ApiMappableObject[];
  @fragment('workflow-connector/customer-data-verification/contextual-identifier-config')
  declare contextualIdentifierConfig: ContextualIdentifierConfig;

  @hasMany('workflow-connector/action', { async: false }) declare children: Action[];
  @belongsTo('workflow-connector/action', { async: false, inverse: 'children' })
  declare parent: Action;

  @service declare attributeService: any;
  @tracked attributeData: Record<string, { name: string; type: string }> = {};

  didLoad() {
    this.hydrateMappingObjects();
    this.setTestedRequest();
    this.setDefaultRuleset();
    this.attributeData = this.getAttributeNamesAndTypes();
  }

  setNoAuthenticationChosen(value: boolean | null) {
    if (this.isFinStandaloneAction) {
      this.set('noAuthenticationChosen', value);
    } else {
      this.set('noAuthenticationChosen', null);
    }
  }

  get createdByAdmin() {
    return Admin.peekAndMaybeLoad(this.store, this.createdByAdminId?.toString());
  }

  get updatedByAdmin() {
    return Admin.peekAndMaybeLoad(this.store, this.updatedByAdminId?.toString());
  }

  get canBeSetLive() {
    // TODO connect to validations
    // add canBeSaved()
    return this.state === 'draft' && this.isValid;
  }

  // TODO rename
  get attributes() {
    let parameterRegex = new RegExp(/{{(.*?)}}/g);
    let text = this.url || '';
    if (['post', 'put', 'patch'].includes(this.httpMethod)) {
      text += this.body;
    }
    return text.matchAll(parameterRegex);
  }

  get usedAttributeArray(): string[] {
    return [...this.attributes].map((match) => match[1]);
  }

  get actionTested() {
    // TODO check if test result is successful
    return isPresent(this.testResult?.actionResponse);
  }

  get actionNotTested() {
    return !this.actionTested;
  }

  get apiMappableObjectsPresent() {
    return isPresent(this.apiMappableObjects);
  }

  // @ts-ignore this should be renamed to no longer override the built-in isValid property
  get isValid() {
    return (
      this.isGeneralConfigurationValid &&
      this.isRequestConfigurationValid &&
      this.hasValidResponse &&
      this.isResponseConfigurationValid
    );
  }

  get isGeneralConfigurationValid() {
    if (!this.isFinStandaloneAction) {
      return this.validations.attrs.name.isValid;
    }

    return (
      this.validations.attrs.name.isValid &&
      this.validations.attrs.description.isValid &&
      this.isAudienceValid
    );
  }

  get isRequestConfigurationValid() {
    return !this.installUrlErrors && this.isRequestBodyValid;
  }

  get isRequestBodyValid() {
    let contentTypeHeader = this.headers?.find((h) => h.name === 'Content-Type');

    if (contentTypeHeader?.value !== ContentType.JSON) {
      return true;
    }

    if (!this.body) {
      return true;
    }

    return this.isValidJsonBody(this.body);
  }

  private isValidJsonBody(requestBodyString: string): boolean {
    try {
      let validateStr = requestBodyString.replaceAll('<br>', '\n');
      let parameterRegex = new RegExp(/{{(.*?)}}/g);
      let matches = [...validateStr.matchAll(parameterRegex)];

      matches.forEach((match) => {
        let attributeName = match[1].trim();
        let templateVar = match[0];
        let attributeType = this.getAttributeType(attributeName);
        let replacement = this.getReplacementValueForType(attributeType, templateVar);

        validateStr = validateStr.replaceAll(templateVar, replacement);
      });

      JSON.parse(validateStr);
      return true;
    } catch (e) {
      return false;
    }
  }

  private getAttributeType(attributeName: string): string {
    let paramDescriptor = this.paramDescriptors.find((param) => param.name === attributeName);
    if (paramDescriptor) {
      return (paramDescriptor.type || '').toLowerCase();
    }
    return (this.attributeData[attributeName]?.type || '').toLowerCase();
  }

  private getReplacementValueForType(attributeType: string, templateVariable: string): string {
    switch (true) {
      case ['integer', 'decimal', 'number', 'float', 'last_conversation_rating_given'].includes(
        attributeType,
      ):
        return '0';

      case attributeType === 'boolean':
        return 'true';

      case attributeType === 'conversation_attribute_list':
        return '[]';

      case attributeType === 'user_event':
        return '{}';

      case attributeType.endsWith('_id'):
        return '1000';

      default:
        return templateVariable;
    }
  }

  get hasValidResponse() {
    return (this.isTestResponse && this.testIsValid) || (this.isMockResponse && this.mockIsValid);
  }

  get isResponseMappingValid() {
    return isEmpty(this.responseMappingValidationErrors);
  }

  get responseAttributesSelectionValid(): boolean {
    if (!this.isFinStandaloneAction) {
      return true;
    }

    if (this.includeFullResponse) {
      return true;
    }

    return this.responseFields.any((field) => !field.redacted);
  }

  get isResponseConfigurationValid(): boolean {
    return this.isResponseMappingValid && this.responseAttributesSelectionValid;
  }

  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes || (this.isFinStandaloneAction && this.ruleset?.hasDirtyAttributes)
    );
  }

  get hasAttributeChangesThatAffectPreview() {
    let changedAttributes = this.changedAttributes();
    let isResponseFieldsChanged =
      changedAttributes.responseFields &&
      !_.isEqual(changedAttributes.responseFields[1], this.responseFields);

    return (
      isResponseFieldsChanged ||
      this.mockResponse?.hasDirtyAttributes ||
      this.testResult?.hasDirtyAttributes
    );
  }

  get isAudienceValid() {
    return (
      !this.isFinStandaloneAction || (this.ruleset?.audienceValid && this.isSecuritySectionValid)
    );
  }

  get isSecuritySectionValid() {
    if (!this.isFinStandaloneAction) {
      return true;
    }
    if (this.appService.app?.canUseFeature('team-actions-allow-no-authentication')) {
      return true;
    }
    return this.otpAuthEnabled || this.userProtectedFromSpoofing;
  }

  get isSecuritySettingsValid() {
    if (!this.isFinStandaloneAction) {
      return true;
    }
    return (
      this.otpAuthEnabled ||
      this.isMessengerInstalledAndProtected ||
      (this.appService.app?.canUseFeature('team-actions-allow-no-authentication') &&
        this.noAuthenticationChosen)
    );
  }

  get installUrlErrors() {
    return this.validations.errors?.findBy('attribute', 'url');
  }

  get responseMappingValidationErrors() {
    let objectMappingsValidationResult = this.objectMappings
      .map((objectMapping) => objectMapping.validationErrors)
      .flat();

    if (isPresent(objectMappingsValidationResult)) {
      return ['invalid_object_mappings'];
    }

    return [];
  }

  get requiredAttributesWarningPresent() {
    let objectMappingWarnings = this.objectMappings
      .map((objectMapping) => objectMapping.missingRequiredAttributeMappingWarnings)
      .flat();

    if (isPresent(objectMappingWarnings)) {
      return true;
    }
    return false;
  }

  get isTestResponse() {
    return this.configurationResponseType === 'test_response_type';
  }

  get isMockResponse() {
    return this.configurationResponseType === 'mock_response_type';
  }

  get testIsValid() {
    if (isPresent(this.testResult?.actionResponse)) {
      if (this.isJsonInvalid) {
        return false;
      } else if (!this.hasDirtyAttributes) {
        return true;
      } else if (isPresent(this.testResult.testedAction)) {
        return this.isTestActionValid;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get isJsonInvalid() {
    if (!this.isFinStandaloneAction) {
      return false;
    }

    if (!this.testResult?.actionResponse?.rawBody) {
      return false;
    }

    try {
      JSON.parse(this.testResult?.actionResponse?.rawBody);
    } catch (e) {
      return true;
    }
    return false;
  }

  get mockIsValid() {
    try {
      JSON.parse(this.mockResponse?.body);
    } catch (e) {
      return false;
    }
    return true;
  }

  get isTestActionValid() {
    return (
      this.httpMethod === this.testResult.testedAction.httpMethod &&
      this.url === this.testResult.testedAction.url &&
      this.headers === this.testResult.testedAction.headers &&
      this.body === this.testResult.testedAction.body &&
      this.tokenId === this.testResult.testedAction.tokenId
    );
  }

  get shouldRerunTest() {
    return !(this.testIsValid || !isPresent(this.testResult?.actionResponse));
  }

  hydrateMappingObjects() {
    // this wires up objectMappings and attributeMappings with respective apiMappableObjects and apiMappableAttributes
    this.objectMappings.forEach((objectMapping) => {
      objectMapping.hydrateParent(this);
    });
  }

  setTestedRequest() {
    if (!this.testResult) {
      // @ts-ignore we should initialize this instead of setting it to an empty object
      this.testResult = {};
    }

    this.testResult.testedAction = {};
    this.testResult.testedAction.httpMethod = this.httpMethod;
    this.testResult.testedAction.url = this.url;
    this.testResult.testedAction.headers = this.headers;
    this.testResult.testedAction.body = this.body;
    this.testResult.testedAction.tokenId = this.tokenId;
  }

  rollbackAllAttributes() {
    this.rollbackAttributes();
    this.ruleset.rollbackAttributes();
  }

  get appPackageName() {
    return humanize([this.appPackageCode]);
  }

  updatePredicates(predicates: any) {
    this.ruleset.set('predicateGroup.predicates', copy(predicates));
  }

  updateRolePredicates(rolePredicates: any) {
    this.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));
  }

  updateOtpAuthEnabled() {
    this.otpAuthEnabled = !this.otpAuthEnabled;
    if (this.otpAuthEnabled) {
      this.setNoAuthenticationChosen(false);
    }
  }

  toggleFinWriteAction() {
    this.finWriteAction = !this.finWriteAction;
  }

  getAttributeNamesAndTypes() {
    let attributeData: Record<string, { name: string; type: string }> = {};

    this.allAttributes.forEach((attribute: any) => {
      attributeData[attribute.identifier] = { name: attribute.name, type: attribute.type };
    });

    this.resolver.eventAttributes.forEach((attribute: any) => {
      attributeData[attribute.templatable_identifier] = {
        name: attribute.name,
        type: attribute.type,
      };
    });
    return attributeData;
  }

  get resolver(): any {
    return new AttributeInfoResolver({
      attributes: this.attributeService?.allAttributes || [],
      includeAppAttributes: true,
    });
  }

  get allAttributes() {
    if (!this.attributeService) {
      return [];
    }
    return [...this.attributeService.allAttributes, ...this.resolver.manualAppAttributes];
  }

  getAttributeName(identifier: string): string | undefined {
    return this.attributeData[identifier]?.name;
  }

  refreshAttributeNames() {
    this.attributeData = this.getAttributeNamesAndTypes();
  }
}
